import React, { useReducer, useCallback } from 'react';
import MenuContext from './statusContext';
import MenuReducer, { SET_MENU_OPEN, SET_POPUP_OPEN, SET_IS_LOADING } from './statusReducer';

const initialState = {
  isOpen: false,
  isLoading: false
};

const MenuState = ({ children }) => {
  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const setMenuOpen = useCallback(async options => {
    requestAnimationFrame(() => {
      dispatch({
        type: SET_MENU_OPEN,
        payload: options
      });
      // if (options === true) {
      //   document.documentElement.style.overflow = 'hidden';
      // } else {
      //   document.documentElement.style.overflow = '';
      // }
    })
  }, []);

  const setPopupOpen = useCallback(async options => {
    requestAnimationFrame(() => {
      dispatch({
        type: SET_POPUP_OPEN,
        payload: options
      });
    })
  }, []);

  const setIsLoading = useCallback(async options => {
    requestAnimationFrame(() => {
      dispatch({
        type: SET_IS_LOADING,
        payload: options
      });
      if (options === true) {
        setMenuOpen(false);
        document.documentElement.style.backgroundColor="#FFFFFF";
      }
    })
  }, []);

  return (
    <MenuContext.Provider
      value={{
        ...state,
        setMenuOpen,
        setPopupOpen,
        setIsLoading
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuState;
