import React, { useContext, useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import {Elastic, Expo, Power2, TextPlugin} from 'gsap/all';
import statusContext from 'contexts/status/statusContext';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import logoWhiteImg from 'media/image/logoWhite.png';
import logoBlackImg from 'media/image/logoBlack.png';
gsap.registerPlugin(ScrollTrigger);

const AnimateLogo = ({autoplay = true}) => {
  const logoL = useRef();
  const logoU = useRef();
  const logoM = useRef();
  const logoPng = useRef();
  const logoSvg = useRef();
  
  const setLogoL = (ref) => logoL.current = ref;
  const setLogoU = (ref) => logoU.current = ref;
  const setLogoM = (ref) => logoM.current = ref;
  const setLogoPng = (ref) => logoPng.current = ref;
  const setLogoSvg = (ref) => logoSvg.current = ref;
  useEffect(() => {
    
    const aniTimeline = gsap.timeline({paused: true, repeat: -1});
    aniTimeline.add([
      gsap.to(logoL.current, {translateX: '125%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoU.current, {translateY: '125%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoM.current, {translateY: '-125%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
    ]);
    aniTimeline.add([
      gsap.to(logoL.current, {translateY:'125%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoU.current, {translateX:'-125%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoM.current, {translateX:'125%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
    ]);
    aniTimeline.add([
      gsap.to(logoL.current, {translateX: '0%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoU.current, {translateY: '0%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoM.current, {translateY: '0%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
    ]);
    aniTimeline.add([
      gsap.to(logoL.current, {translateY: '0%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoU.current, {translateX: '0%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
      gsap.to(logoM.current, {translateX: '0%',  ease: Power2.easeIn, duration: 0.6, delay: 0.3}),
    ]);
    if (autoplay) {
      aniTimeline.play(0);
    } else {
      aniTimeline.timeScale(2);
      if (document.documentElement.scrollTop <= 10) {
        gsap.set(logoPng.current, {
          translateY: '0%',
          autoAlpha: 1
        });
        gsap.set(logoSvg.current, {
          translateY: '-10%',
          autoAlpha: 0
        });
      } else {
        gsap.set(logoPng.current, {
          translateY: '-10%',
          ausetAlpha: 0
        });
        gsap.to(logoSvg.current, {
          translateY: '0%',
          autoAlpha: 1
        });
      }
      ScrollTrigger.observe({
        type: "wheel,touch, scroll",
        // wheelSpeed: -1,
        onChange: self => {
          // on touch devices like iOS, if we want to prevent scrolling, we must call preventDefault() on the touchstart (Observer doesn't do that because that would also prevent side-scrolling which is undesirable in most cases)
          if (document.documentElement.scrollTop <= 10) {
            gsap.to(logoPng.current, {
              translateY: '0%',
              autoAlpha: 1,
              duration: 0.2
            });
            gsap.to(logoSvg.current, {
              translateY: '-10%',
              autoAlpha: 0,
              duration: 0.2,
              onComplete: () => {
                aniTimeline.time(0);
              }
            });
            aniTimeline.pause();
          } else {
            !autoplay && gsap.to(logoPng.current, {
              translateY: '-10%',
              autoAlpha: 0,
              duration: 0.2
            });
            gsap.to(logoSvg.current, {
              translateY: '0%',
              autoAlpha: 1,
              duration: 0.2
            });
            if (!aniTimeline.isActive()) {
              aniTimeline.play();
            }
          }
        },
        onStop: () => {
            aniTimeline.pause();
        }
      })
    }
    return () => {
      aniTimeline.time(0);
      aniTimeline.kill();
      !autoplay && gsap.killTweensOf(logoPng.current);
      gsap.killTweensOf(logoSvg.current);

    }
  }, [])
  return <div className="animateLogo">
    {!autoplay && <div ref={setLogoPng}>
      <img src={logoWhiteImg} className="logoWhite" />
      <img src={logoBlackImg} className="logoBlack" />
    </div>}
    <svg ref={setLogoSvg} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 841.89 595.28">
      <path fill={autoplay? "#000": "currentColor"} ref={setLogoL} d="M190.01,73.61h103.03v113.17h102.24c0,47.65-38.63,86.28-86.28,86.28h-118.99V73.61Z"/>
      <path fill={autoplay? "#000": "currentColor"} ref={setLogoU} d="M549.16,273.06c-9.41,0-18.49-1.18-27.25-3.53-8.76-2.35-16.92-5.71-24.48-10.07-7.56-4.36-14.47-9.58-20.72-15.65-6.26-6.08-11.61-12.81-16.07-20.21-4.45-7.4-7.91-15.36-10.36-23.88-2.45-8.52-3.68-17.39-3.68-26.6v-99.51h205.27v99.51c-.1,9.21-1.38,18.08-3.83,26.6-2.45,8.52-5.91,16.49-10.36,23.88-4.45,7.4-9.81,14.13-16.07,20.21-6.26,6.08-13.16,11.29-20.72,15.65-7.56,4.36-15.72,7.72-24.48,10.07-8.76,2.35-17.84,3.53-27.25,3.53Z"/>
      <path fill={autoplay? "#000": "currentColor"} ref={setLogoM} d="M343.61,333.37c-8.15-4.51-16.98-7.83-26.29-9.7-5.87-1.2-11.94-1.82-18.15-1.82h-6.53l-.11,96.64c-1.56-37.02-21.94-68.89-51.3-85.12-8.15-4.51-16.98-7.83-26.29-9.7-5.87-1.2-11.94-1.82-18.15-1.82h-6.53l-.24,199.82h204.72l.28-98.23v-.68c-.11-38.83-20.92-72.54-51.4-89.39Z"/>
    </svg>
  </div>;
}

export default AnimateLogo;