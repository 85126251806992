import React, { useContext, useEffect, useRef } from 'react';

// import {TweenMax} from 'gsap';

// import ScrollContext from 'contexts/scroll/scrollContext';

import './scrollComponent.css';
import { useLocation } from 'react-router-dom';

const ScrollComponent = ({
  children,
  snapToPage = false,
  direction = 'y',
  main = false
}) => {
  const location = useLocation();
  // const {updateScrollPosition} = useContext(ScrollContext);
  const scrollWrapper = useRef(null);
  const scrollPosition = useRef(0);
  const touchPosition = useRef(0);
  const touchDelta = useRef(0);
  const touchStartTime = useRef(null);
  const scrollContainer = useRef(null);
  const scrollAnimationFrame = useRef(null);

  const setScrollWrapper = (ref) => {
    scrollWrapper.current = ref;
  }
  const setScrollContainer = (ref) => {
    scrollContainer.current = ref;
  }
  // const onTouchStart = (e) => {
  //   return; 
  //   touchPosition.current = e.touches[0]['clientY'];
  //   touchStartTime.current = Date.now();
  //   touchDelta.current = touchPosition.current;
  // }
  // const onScrollEvent = (e) => {
  //   e.preventDefault();
  //   let delta = 0;
  //   if (e.touches && e.touches.length) {
  //     delta = touchPosition.current - e.touches[0]['clientY'];
  //     touchPosition.current = e.touches[0]['clientY'];
  //     // touchDelta.current = delta;
  //   } else {
  //     delta = Math.sign(e.deltaY) * window.innerHeight / 10;
  //   }
  //   scrollPosition.current -= delta;
  //   scrollPosition.current = Math.max(Math.min(0 , scrollPosition.current), scrollWrapper.current.offsetHeight - scrollContainer.current.offsetHeight);

  //   cancelAnimationFrame(scrollAnimationFrame);
  //   scrollAnimationFrame.current = requestAnimationFrame(() => {
  //     TweenMax.to(scrollContainer.current, 0.8, {
  //       [direction]: scrollPosition.current,
  //       onUpdate: () => {
  //         if (main) {
  //           updateScrollPosition({
  //             scrollX: 0,
  //             scrollY: -parseInt(scrollContainer.current._gsap.y)
  //           })
  //         }
  //       }
  //     })
  //   })
  // }
  // const onTouchEnd = (e) => {
  //   return; 
  //   const deltaTime = Date.now() - touchStartTime.current;
  //   touchDelta.current = touchDelta.current - e.changedTouches[0]['clientY'];
  //   console.log(touchDelta.current);
  //   if (snapToPage) {
  //     if (touchDelta.current < -10) {
  //       scrollPosition.current = Math.ceil(scrollPosition.current / scrollWrapper.current.offsetHeight) * scrollWrapper.current.offsetHeight;
  //     } else if (touchDelta.current > 10) {
  //       scrollPosition.current = Math.floor(scrollPosition.current / scrollWrapper.current.offsetHeight) * scrollWrapper.current.offsetHeight;
  //     } else {
  //       scrollPosition.current = Math.round(scrollPosition.current / scrollWrapper.current.offsetHeight) * scrollWrapper.current.offsetHeight;
  //     }
  //   } else {
  //     scrollPosition.current -= (deltaTime < 200? touchDelta.current * 2: 0);
  //   }
  //   scrollPosition.current = Math.max(Math.min(0 , scrollPosition.current), scrollWrapper.current.offsetHeight - scrollContainer.current.offsetHeight);

  //   touchDelta.current = 0;

  //   cancelAnimationFrame(scrollAnimationFrame);
  //   scrollAnimationFrame.current = requestAnimationFrame(() => {
  //     TweenMax.to(scrollContainer.current, 0.6, {
  //       [direction]: scrollPosition.current,
  //       onUpdate: () => {
  //         if (main) {
  //           updateScrollPosition({
  //             scrollX: 0,
  //             scrollY: -parseInt(scrollContainer.current._gsap.y)
  //           })
  //         }
  //       }
  //     })
  //   })
  // }
  useEffect(() => {
    // if (scrollWrapper.current) {
    //   scrollWrapper.current.addEventListener('wheel', onScrollEvent)
    //   scrollWrapper.current.addEventListener('touchstart', onTouchStart)
    //   scrollWrapper.current.addEventListener('touchmove', onScrollEvent)
    //   window.addEventListener('touchend', onTouchEnd)
    // }
    return () => {
      // if (scrollWrapper.current) {
      //   scrollWrapper.current.removeEventListener('wheel', onScrollEvent)
      //   scrollWrapper.current.removeEventListener('touchstart', onTouchStart)
      //   scrollWrapper.current.removeEventListener('touchmove', onScrollEvent)
      //   window.removeEventListener('touchend', onTouchEnd)
      // }
    }
  }, []);
  useEffect(() => {

    console.log('pageChanged');
    scrollPosition.current = 0;
    // TweenMax.set(scrollWrapper.current, {
    //   scrollLeft: 0,
    //   scrollTop: 0
    // })
    // TweenMax.set(scrollContainer.current, {
    //   x: 0,
    //   y: 0
    // })
    // updateScrollPosition({
    //   scrollX: 0,
    //   scrollY: 0
    // })
  }, [location])
  return <div className="scrollWrapper" ref={setScrollWrapper}>
    <div className="scrollContent" ref={setScrollContainer}>
      {children}
    </div>
  </div>;
}

export default ScrollComponent;