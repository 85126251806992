import statusContext from 'contexts/status/statusContext';
import React, { useContext, useEffect, useState } from 'react';
// import LoadingGif from 'media/image/Final_OPening-2_1.gif';
import { useLocation } from 'react-router';
import AnimateLogo from './animateLogo';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const LoadingComponent = () => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(location);
    const {isLoading, setIsLoading} = useContext(statusContext);
    // console.log('isLoading', isLoading);
    useEffect(() => {
      if (prevLocation !== location.pathname) {
        console.log(prevLocation, location.pathname);
        setIsLoading(true);
        setPrevLocation(location.pathname);
      }
      ScrollTrigger.refresh();
      // setTimeout(() => setIsLoading(false), 3650);
    }, [setIsLoading, location, prevLocation]);
    return <div id="loadingCover" className={isLoading? 'active': 'hide'}>
    {/* return <div id="loadingCover" className={true? 'active': 'hide'}> */}
      {isLoading && <AnimateLogo/>}
    </div>;
}

export default LoadingComponent;