// const apiBaseUrl = 'http://localhost/morninggiants/api.php';
// const apiBaseUrl = 'http://10.0.1.26/morninggiants/api.php';
// const apiBaseUrl = 'http://localhost:3000/json';
const apiBaseUrl = '/json';
// const apiBaseUrl = 'http://dev.ioiocreative.com/hung/morningGiants/cms/api.php';
// const apiBaseUrl = 'https://morninggiantsstudio.com/dev/cms/api.php';
// const apiBaseUrl = 'http://localhost/dev/cms/api.php';
// const apiBaseUrl = 'https://morninggiantsstudio.com/cms/api.php';
export {
  apiBaseUrl
};