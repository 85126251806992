import React, { useContext, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import statusContext from "contexts/status/statusContext";

gsap.registerPlugin(ScrollTrigger);

const SmoothScroll = ({
  smoothness, children
}) => {
  const {isLoading} = useContext(statusContext);
  const scrollerRef = useRef(null);
  const contentRef = useRef(null);
  const setScrollerRef = (ref) => scrollerRef.current = ref;
  const setContentRef = (ref) => contentRef.current = ref;

  useEffect(() => {
  // this is the helper function that sets it all up. Pass in the content <div> and then the wrapping viewport <div> (can be the elements or selector text). It also sets the default "scroller" to the content so you don't have to do that on all your ScrollTriggers.
    const content = contentRef.current;
    smoothness = smoothness || 1;

    gsap.set(scrollerRef.current, {overflow: "hidden", position: "fixed", height: "100%", width: "100%", top: 0, left: 0, right: 0, bottom: 0});
    gsap.set(content, {overflow: "visible", width: "100%"});

    let getProp = gsap.getProperty(content),
      setProp = gsap.quickSetter(content, "y", "px"),
      setScroll = ScrollTrigger.getScrollFunc(window),
      removeScroll = () => content.style.overflow = "visible",
      killScrub = trigger => {
        let scrub = trigger.getTween ? trigger.getTween() : gsap.getTweensOf(trigger.animation)[0]; // getTween() was added in 3.6.2
        scrub && scrub.pause();
        trigger.animation.progress(trigger.progress);
      },
      height, isProxyScrolling;

    function refreshHeight() {
      height = content.clientHeight;
      content.style.overflow = "visible"
      document.body.style.height = height + "px";
      return height - document.documentElement.clientHeight;
    }

    ScrollTrigger.addEventListener("refresh", () => {
      removeScroll();
      requestAnimationFrame(removeScroll);
    })
    ScrollTrigger.defaults({scroller: content});
    ScrollTrigger.prototype.update = p => p; // works around an issue in ScrollTrigger 3.6.1 and earlier (fixed in 3.6.2, so this line could be deleted if you're using 3.6.2 or later)

    ScrollTrigger.scrollerProxy(content, {
      scrollTop(value) {
        if (arguments.length) {
          isProxyScrolling = true; // otherwise, if snapping was applied (or anything that attempted to SET the scroll proxy's scroll position), we'd set the scroll here which would then (on the next tick) update the content tween/ScrollTrigger which would try to smoothly animate to that new value, thus the scrub tween would impede the progress. So we use this flag to respond accordingly in the ScrollTrigger's onUpdate and effectively force the scrub to its end immediately.
          setProp(-value);
          setScroll(value);
          return;
        }
        return -getProp("y");
      },
      scrollHeight: () => document.body.scrollHeight,
      getBoundingClientRect() {
        return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
      }
    });

    const scrollTrigger = ScrollTrigger.create({
      animation: gsap.fromTo(content, {y:0}, {
        y: () => document.documentElement.clientHeight - height,
        ease: "none",
        onUpdate: ScrollTrigger.update
      }),
      scroller: window,
      invalidateOnRefresh: true,
      start: 0,
      end: refreshHeight,
      refreshPriority: -999,
      scrub: smoothness,
      onUpdate: self => {
        if (isProxyScrolling) {
          killScrub(self);
          isProxyScrolling = false;
        }
        if (self.progress > 0) {
          document.body.classList.add("scrolled");
        } else {
          document.body.classList.remove("scrolled");
        }

      },
      onRefresh: killScrub // when the screen resizes, we just want the animation to immediately go to the appropriate spot rather than animating there, so basically kill the scrub.
    });
    if (isLoading == true) {
      document.documentElement.scrollTop = 0;
      document.body.classList.remove("scrolled");
      let scrub = scrollTrigger.getTween ? scrollTrigger.getTween() : gsap.getTweensOf(scrollTrigger.animation)[0]; // getTween() was added in 3.6.2
      scrub && scrub.pause();
      scrollTrigger.animation.progress(0);
    }
    // let scrollerRefreshTimer;
    // const keepRefreshScroller = () => {
    //   refreshHeight();
    //   scrollerRefreshTimer = requestAnimationFrame(keepRefreshScroller);
    // }
    // const refreshScroller = setTimeout(() => {
    //   refreshHeight();
    //   // scrollerRefreshTimer = requestAnimationFrame(keepRefreshScroller);
    // }, 1000);
    // removeScroll();
    // keepRefreshScroller();
    return () => {
      // cancelAnimationFrame(scrollerRefreshTimer);
      // clearTimeout(refreshScroller);
      scrollTrigger.kill();
      removeScroll();
    }


  }, [isLoading])

  useEffect(() => {
    let refrestTimeout;
    const refreshScroll = () => {
      clearTimeout(refrestTimeout);
      refrestTimeout = setTimeout(() => ScrollTrigger.refresh(), 100);
    }
    window.addEventListener('resize' , refreshScroll);
    return () => {
      clearTimeout(refrestTimeout);
      window.removeEventListener('resize' , refreshScroll);
    }
  }, [])

  return <div ref={setScrollerRef}>
    <div ref={setContentRef}>
      {children}
    </div>
  </div>;
}

export default SmoothScroll;