import React, { Suspense, lazy, memo } from 'react';
// import React, { useEffect, useState, Suspense, lazy } from 'react';
// import {HashRouter, BrowserRouter, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import { BrowserRouter, Switch, Route, Redirect, generatePath, Navigate } from 'react-router-dom';
// import asyncLoadingPage from 'components/asyncLoadingComponent.js';
import routes from 'globals/routes';
import StatusState from 'contexts/status/statusState';
import DataState from 'contexts/data/dataState';
// import Footer from 'containers/footer';
import ScrollComponent from 'components/scrollComponent';
import LoadingComponent from 'components/loadingComponent';
// import Menu from 'containers/menu';
// import Cursor from 'containers/cursor';
import './App.css';
import SmoothScroll from 'components/smoothScroll';
// import VideoPopup from 'containers/project/videoPopup';
// import logo from './logo.svg';
// import ReactGA from 'react-ga';
// import Analytics from 'react-router-ga';
import logoBlackImg from 'media/image/logoBlack.png';

const getRenderPropForRoute = (pageId) => {
  const AppComponent = lazy(()=> import('pages/' + pageId));
  // console.log("getRenderPropForRoute",pageId);
  return (match) => { return (<AppComponent match={match} />) };
}

const getComponentPropForRoute = (pageId) => {
  return lazy(()=> import('pages/' + pageId));
}
const getFooterForRoute = (match) => {
  return lazy(()=> import('containers/footer'));
}
const LazyComponent = ({path}) => {
  const AppComponent = lazy(()=> import(''+path));
  return <Suspense><AppComponent/></Suspense>;
}

// const GetFooterForRoute = () => {
//   const AppComponent = lazy(()=> import('containers/footer'));
//   return <AppComponent />;
// }

const App = () => {
  return (
    <DataState>
    {/* <CursorState> */}
      <StatusState>
            {/* <ScrollState> */}
              <div id="mobileCover">
                <div className="hints">
                <img id="mobileLogo" alt="logo" src="/media/icon/mobileIcon.svg" />
                The mobile site is currently under maintenance. Please use a desktop or laptop for the best experience.</div>
              </div>
              <BrowserRouter basename="/">
                {/* <Analytics id="UA-197153072-1"> */}
                  {/* <Cursor /> */}
                  {/* <LazyComponent path={'containers/cursor'} /> */}
                  {/* <Menu /> */}
                  {/* <Route render={(match) => {
                      const location = match.location;
                      console.log(match);
                      console.log([routes.contact].includes(location.pathname));
                      // console.log(getRenderPropForRoute('footer'));
                      // return LazyComponent({path:'containers/footer'});
                      // return [routes.contact].includes(location.pathname)? null: LazyComponent({path:'containers/footer'})
                      return [routes.landing2].includes(location.pathname)? null: <LazyComponent path={'containers/menu'} />
                    }} /> */}
                  {/* <VideoPopup /> */}
                  {/* <LazyComponent path={'containers/project/videoPopup'} /> */}
                  <LoadingComponent />
                  <LazyComponent path={'containers/menu'} />

                  {/* <LazyComponent path={'components/loadingComponent'} /> */}
                  {/* <ScrollComponent main={true}> */}
                  <SmoothScroll>

                    <Suspense>
                      <Switch>
                        {/* <Route path="/:lang"> */}
                        {/* <Route path="/">
                          <Redirect to={generatePath(routes.landing, { lang: "en" })} />
                        </Route> */}
                        <Route exact path={routes.landing} render={getRenderPropForRoute('landing')} />
                        <Route exact path={routes.social} component={getComponentPropForRoute('social')} />
                        <Route exact path={routes.about} component={getComponentPropForRoute('about')} />
                        <Route exact path={routes.works} component={getComponentPropForRoute('works')} />
                        <Route exact path={routes.project} component={getComponentPropForRoute('project')} />
                        <Route exact path={routes.team} component={getComponentPropForRoute('team')} />
                        <Route exact path={routes.contact} component={getComponentPropForRoute('contact')} />
                        <Route exact path={routes.termsAndConditions} component={getComponentPropForRoute('termsAndCondition')} />
                        <Route path="*">
                          <Redirect to={generatePath(routes.landing, { lang: "en" })} />
                        </Route>
                      </Switch>
                      <Route component={getFooterForRoute()} />
                    </Suspense>
                    {/* <Footer /> */}
                      {/* <Route render={(match) => {
                        const location = match.location;
                        console.log(match);
                        console.log([routes.contact].includes(location.pathname));
                        // console.log(getRenderPropForRoute('footer'));
                        // return LazyComponent({path:'containers/footer'});
                        return [routes.contact].includes(location.pathname)? null: LazyComponent({path:'containers/footer'})
                        // return [routes.contact].includes(location.pathname)? null: <Footer />
                      }} /> */}
                      {/* <Route component={getFooterForRoute()} /> */}
                      {/* <Route path={new RegExp("^(?!.*(" + routes.contact + ")).*$")} component={getFooterForRoute()} /> */}
                      {/* <Route path={"^(?!.*(" + routes.contact + ")).*$"} component={getFooterForRoute()} /> */}
                  {/* </ScrollComponent> */}
                  {/* <PlaygroundButton /> */}
                {/* </Analytics> */}
              </SmoothScroll>

            </BrowserRouter>
            {/* </ScrollState> */}
        </StatusState>
      {/* </CursorState> */}
    </DataState>
  );
}

export default App;