const routes = {
  landing: '/:lang(en|zh)/landing',
  social: '/:lang(en|zh)/social',
  team: '/:lang(en|zh)/team',
  about: '/:lang(en|zh)/about',
  works: '/:lang(en|zh)/works/:filter?',
  project: '/:lang(en|zh)/project/:projectName',
  contact: '/:lang(en|zh)/contact',
  termsAndConditions: '/:lang(en|zh)/termsAndConditions',
  playground: '/:lang(en|zh)/playground',
  screenTest: '/:lang(en|zh)/screenTest'
};

export default routes;